import api from '@/services/api';

const url = 'compliance-category';

const getCategory = () => api.get(`${url}/read-compliance-category`);

const createCategory = (category) => api.post(`${url}/create-compliance-category`, category);

const updateCategory = (category) => api.put(`${url}/update-compliance-category`, category);

const deleteCategory = (id) => api.delete(`${url}/delete-compliance-category/${id}`);

export default {
  getCategory,
  createCategory,
  updateCategory,
  deleteCategory,
};
